/**
 * Contains all the settings for this app - they are stored and retreived from
 * LocalStorage
 *
 * @see
 * @type {{}}
 */
let options = {
  activeTab: 'password', // password, passphrase, uuid
  // analytics: true,
  avoidCharacters: '',
  easyToRead: false,
  easyToSay: false,
  filenameSafe: false,
  noDuplicates: false,
  passwordLength: 16,
  urlSafe: false,
  useDigits: true,
  useLowerCase: true,
  useSymbols: true,
  useUpperCase: true,
  wordCount: 4,
  wordSeparator: ' '
};

// object contains all elements that need manipulation
const ELEMENTS = {
  checkboxEasyToRead: {
    domElement: document.getElementById('easy-to-read'),
  },
  checkboxEasyToSay: {
    domElement: document.getElementById('easy-to-say'),
  },
  checkboxFilenameSafe: {
    domElement: document.getElementById('filename-safe')
  },
  checkboxNoDuplicates: {
    domElement: document.getElementById('no-duplicates')
  },
  checkboxUrlSafe: {
    domElement: document.getElementById('url-safe')
  },
  checkboxUseDigits: {
    domElement: document.getElementById('use-digits')
  },
  checkboxUseLowerCase: {
    domElement: document.getElementById('use-lower-case')
  },
  checkboxUseSymbols: {
    domElement: document.getElementById('use-symbols')
  },
  checkboxUseUpperCase: {
    domElement: document.getElementById('use-upper-case')
  },
  entropyLabel: {
    domElement: document.getElementById('entropy-label')
  },
  copyButton: {
    domElement: document.getElementById('copy-button')
  },
  maxEntropyLabel: {
    domElement: document.getElementById('max-entropy-label')
  },
  password: {
    domElement: document.getElementById('password')
  },
  passwordLengthInput: {
    domElement: document.getElementById('password-length-input'),
    optionsKey: 'passwordLength'
  },
  passwordLengthRange: {
    domElement: document.getElementById('password-length-range'),
    optionsKey: 'passwordLength'
  },
  refreshButton: {
    domElement: document.getElementById('refresh-button')
  },
  scoreLabel: {
    domElement: document.getElementById('score-label')
  },
  strengthMeter: {
    domElement: document.getElementById('strength-meter')
  },
  tabPassphrase: {
    domElement: document.getElementById('tab-passphrase')
  },
  tabPassword: {
    domElement: document.getElementById('tab-password')
  },
  tabUuid: {
    domElement: document.getElementById('tab-uuid')
  }
};

// 32 bit unsigned integer: 2*(2**31) = 4294967296
const MAX_32BIT_UNSIGNED = 2 * (2 ** 31);

// Define character sets - make sure to regex escape!
const LOWERCASE_LETTERS = 'abcdefghijklmnopqrstuvwxyz';
const UPPERCASE_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const DIGITS = '0123456789';
const SYMBOLS = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
const AMBIGUOUS_CHARACTERS = '0O1Il2Z5S6G8B';
const FILENAME_UNSAFE = '/\\:*?"<>|';
const URL_UNSAFE = '~`!@#$%^&()[]{}\'";,<>|*?:=+\\/';

const LOWERCASE_WEIGHTED = (() => {
  // ratios from: https://www3.nd.edu/~busiforc/handouts/cryptography/letterfrequencies.html
  const letterRatioList = [
    {letter: 'e', ratio: 56.88},
    {letter: 'a', ratio: 43.31},
    {letter: 'r', ratio: 38.64},
    {letter: 'i', ratio: 38.45},
    {letter: 'o', ratio: 36.51},
    {letter: 't', ratio: 35.43},
    {letter: 'n', ratio: 33.92},
    {letter: 's', ratio: 29.23},
    {letter: 'l', ratio: 27.98},
    {letter: 'c', ratio: 23.13},
    {letter: 'u', ratio: 18.51},
    {letter: 'd', ratio: 17.25},
    {letter: 'p', ratio: 16.14},
    {letter: 'm', ratio: 15.36},
    {letter: 'h', ratio: 15.31},
    {letter: 'g', ratio: 12.59},
    {letter: 'b', ratio: 10.56},
    {letter: 'f', ratio: 9.24},
    {letter: 'y', ratio: 9.06},
    {letter: 'w', ratio: 6.57},
    {letter: 'k', ratio: 5.61},
    {letter: 'v', ratio: 5.13},
    {letter: 'x', ratio: 1.48},
    {letter: 'z', ratio: 1.39},
    {letter: 'j', ratio: 1.00},
    {letter: 'q', ratio: 1.00},
  ];

  const totalRatio = letterRatioList.reduce(
    (total, item) => total + item.ratio, 0
  );

  // Create the letters string according to the ratios
  const lettersString = letterRatioList.reduce((str, item) => {
    const letterCount = Math.ceil((item.ratio / totalRatio) * 100);
    return str + item.letter.repeat(letterCount);
  }, '');

  return lettersString;
})();

const UPPERCASE_WEIGHTED = LOWERCASE_WEIGHTED.toUpperCase();

/**
 * Initialises the app
 */
function init() {
  setDefaults();
  updateCharacterCheckboxes();
  initEventListeners();
  setPassword();
}

/**
 * Returns the provided string with first character in Uppercase
 *
 * @param value {string}
 * @returns {string}
 */
function firstCharacterToUpperCase(value) {
  return value[0].toUpperCase() + value.substring(1);
}

/**
 * Sets the defaults and/or user settings for each option
 */
function setDefaults() {
  options = getOptions();
  const activeTabKey = `tab${firstCharacterToUpperCase(options.activeTab)}`;

  for(const key in ELEMENTS) {
    const element = ELEMENTS[key];

    if(element.optionsKey) {
      element.domElement.value = options[element.optionsKey];
    }

    // set the active tab
    if(key.startsWith('tab')) {
      element.domElement.checked = key === activeTabKey;
    }

    // set checkboxes
    if(key.startsWith('checkbox')) {
      let optionsKey = key.replace('checkbox', '');
      optionsKey = optionsKey[0].toLowerCase() + optionsKey.substring(1);
      element.domElement.checked = options[optionsKey];
    }
  }
}

/**
 * Makes sure at least one checkbox in the Character category is checked
 */
function updateCharacterCheckboxes() {
  const checkboxes = [];
  const characterOptions = [
    'checkboxUseLowerCase',
    'checkboxUseDigits',
    'checkboxUseSymbols',
    'checkboxUseUpperCase'
  ];

  for(const key in ELEMENTS) {
    if(characterOptions.includes(key) && ELEMENTS[key].domElement.checked) {
      checkboxes.push(ELEMENTS[key]);
    }
  }

  const checkedCheckboxes = checkboxes.filter(item => item.domElement.checked);

  if(checkedCheckboxes.length === 1) {
    checkedCheckboxes[0].domElement.disabled = true;
  } else {
    checkedCheckboxes.forEach(item => {
      item.domElement.disabled = false;
    });
  }
}

/**
 * Sets up the eventListeners and handlers
 */
function initEventListeners() {
  const parent = document.getElementById('generator');

  function handleEvent(event) {

    // checkbox easy-to-read
    if(event.type === 'input' && event.target === ELEMENTS.checkboxEasyToRead.domElement) {
      const checked = ELEMENTS.checkboxEasyToRead.domElement.checked;
      setOption('easyToRead', checked);
      setPassword();
    }

    // checkbox easy-to-say
    if(event.type === 'input' && event.target === ELEMENTS.checkboxEasyToSay.domElement) {
      const checked = ELEMENTS.checkboxEasyToSay.domElement.checked;
      setOption('easyToSay', checked);

      if(checked && ELEMENTS.checkboxUseLowerCase.domElement.checked !== checked
        && ELEMENTS.checkboxUseUpperCase.domElement.checked !== checked) {
        setOption('useLowerCase', checked);
        setOption('useUpperCase', checked);
        ELEMENTS.checkboxUseLowerCase.domElement.checked = checked;
        ELEMENTS.checkboxUseUpperCase.domElement.checked = checked;
      }

      if(checked) {
        setOption('useDigits', !checked);
        setOption('useSymbols', !checked);
        ELEMENTS.checkboxUseDigits.domElement.checked = !checked;
        ELEMENTS.checkboxUseSymbols.domElement.checked = !checked;
      }

      updateCharacterCheckboxes();
      setPassword();
    }

    // checkbox filename-safe
    if(event.type === 'input' && event.target === ELEMENTS.checkboxFilenameSafe.domElement) {
      setOption('filenameSafe', ELEMENTS.checkboxFilenameSafe.domElement.checked);
      setPassword();
    }

    // checkbox no-duplicates
    if(event.type === 'input' && event.target === ELEMENTS.checkboxNoDuplicates.domElement) {
      setOption('noDuplicates', ELEMENTS.checkboxNoDuplicates.domElement.checked);
      setPassword();
    }

    // checkbox url-safe
    if(event.type === 'input' && event.target === ELEMENTS.checkboxUrlSafe.domElement) {
      setOption('urlSafe', ELEMENTS.checkboxUrlSafe.domElement.checked);
      setPassword();
    }

    // checkbox use-lower-case
    if(event.type === 'input' && event.target === ELEMENTS.checkboxUseLowerCase.domElement) {
      const checked = ELEMENTS.checkboxUseLowerCase.domElement.checked;
      updateCharacterCheckboxes();
      setOption('useLowerCase', checked);
      setPassword();
    }

    // checkbox use-digits
    if(event.type === 'input' && event.target === ELEMENTS.checkboxUseDigits.domElement) {
      const checked = ELEMENTS.checkboxUseDigits.domElement.checked;
      updateCharacterCheckboxes();
      setOption('useDigits', checked);

      if(checked) {
        setOption('easyToSay', !checked);
        ELEMENTS.checkboxEasyToSay.domElement.checked = !checked;
      }

      setPassword();
    }

    // checkbox use-symbols
    if(event.type === 'input' && event.target === ELEMENTS.checkboxUseSymbols.domElement) {
      const checked = ELEMENTS.checkboxUseSymbols.domElement.checked;
      updateCharacterCheckboxes();
      setOption('useSymbols', checked);

      if(checked) {
        setOption('easyToSay', !checked);
        ELEMENTS.checkboxEasyToSay.domElement.checked = !checked;
      }

      setPassword();
    }

    // checkbox use-upper-case
    if(event.type === 'input' && event.target === ELEMENTS.checkboxUseUpperCase.domElement) {
      updateCharacterCheckboxes();
      setOption('useUpperCase', ELEMENTS.checkboxUseUpperCase.domElement.checked);
      setPassword();
    }

    // copy button
    if(event.type === 'click' && event.target === ELEMENTS.copyButton.domElement) {
      ELEMENTS.password.domElement.focus();
      window.navigator.clipboard.writeText(ELEMENTS.password.domElement.value).then(() => {
        toggleSpinRefreshButton();
      });
    }

    // password
    if(event.type === 'click' && event.target === ELEMENTS.password.domElement) {
      window.navigator.clipboard.writeText(ELEMENTS.password.domElement.value).then(() => {
        toggleSpinRefreshButton();
      });
    }

    // passwordLengthInput
    if(event.type === 'input' && event.target === ELEMENTS.passwordLengthInput.domElement) {
      const length = event.target.value;
      ELEMENTS.passwordLengthRange.domElement.value = length;
      setOption('passwordLength', length);
      setPassword();
    }

    // passwordLengthRange
    if(event.type === 'input' && event.target === ELEMENTS.passwordLengthRange.domElement) {
      const length = event.target.value;
      ELEMENTS.passwordLengthInput.domElement.value = length;
      setOption('passwordLength', length);
      setPassword();
    }

    // refresh button
    if(event.type === 'click' && event.target === ELEMENTS.refreshButton.domElement) {
      toggleSpinRefreshButton();
      setPassword();
    }

    // tab passphrase
    if(event.type === 'input' && event.target === ELEMENTS.tabPassphrase.domElement) {
      setOption('activeTab', 'passphrase');
      setPassword();
    }

    // tab password
    if(event.type === 'input' && event.target === ELEMENTS.tabPassword.domElement) {
      setOption('activeTab', 'password');
      setPassword();
    }

    // tab uuid
    if(event.type === 'input' && event.target === ELEMENTS.tabUuid.domElement) {
      setOption('activeTab', 'uuid');
      setPassword();
    }
  }

  parent.addEventListener('input', handleEvent, false);
  parent.addEventListener('click', handleEvent, false);
  document.addEventListener('keydown', event => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event
    if(event.key === 'Enter'){
      setPassword();
    }
  });
}

// /**
//  * Sends a custom event to Google Analytics (GA4)
//  *
//  * @param {string} event - the event name
//  * @param {object} options - optional option object
//  */
// function trackAnalyticsEvent(event = 'custom_event', options = {}) {
//   if(options.analytics && gtag instanceof Function) {
//
//     console.log('event', event, {
//       'app_name': 'SafePass.guru',
//       ...options
//     });
//
//     // gtag('event', 'screen_view', {
//     //   'app_name': 'myAppName',
//     //   'screen_name': 'Home'
//     // });
//   }
// }

/**
 * Toggles the spinning state of the refresh button
 *
 * @todo implement / fix
 */
function toggleSpinRefreshButton() {
  ELEMENTS.refreshButton.domElement.classList.toggle('spinning');
}

/**
 * Sets the password
 */
function setPassword() {
  let value;

  switch(options.activeTab) {
    case 'password':
      value = generateRandomPassword();
      showPasswordStrength(value);
      break;
    case 'passphrase':
      value = 'PASSPHRASE';
      break;
    case 'uuid':
      value = generateRandomUuid();
      break;
  }

  ELEMENTS.password.domElement.value = value;
}

/**
 * Saves the settings to LocalStorage
 */
function saveOptions(options) {
  localStorage.setItem('options', JSON.stringify(options));
}

/**
 * Returns the options object containing the user option and/or defaults
 *
 * @returns {{}}
 */
function getOptions() {
  const savedOptions = JSON.parse(localStorage.getItem('options')) || {};

  return {
    ...options,
    ...savedOptions
  };
}

/**
 * Sets and option in the options Object and stores it in LocalStorage
 *
 * @param key
 * @param value
 */
function setOption(key, value) {
  options[key] = value;
  saveOptions(options);
}

function generateRandomUuid() {
  if(crypto.randomUUID instanceof Function) {
    return crypto.randomUUID();
  } else if(URL.createObjectURL instanceof Function) {
    return URL.createObjectURL(new Blob([])).slice(-36);
  } else {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ (getRandomNumber() * MAX_32BIT_UNSIGNED) & 15 >> c / 4).toString(16)
    );
  }
}

/**
 * Returns a random number, tries to use `crypto.getRandomValues()` but falls
 * back to Math.random() if not supported
 *
 * @returns {number}
 */
function getRandomNumber() {
  if(crypto.getRandomValues instanceof Function) {
    return crypto.getRandomValues(new Uint32Array(1))[0] / MAX_32BIT_UNSIGNED;
  } else {
    return Math.random();
  }
}

/**
 * Shuffes the characters in a string using the Fisher-Yates
 * (also known as Knuth) shuffle algorithm
 *
 * @param str {string}
 * @returns {string} the shuffled string
 */
function fisherYatesShuffle(str) {
  const array = str.split('');
  for(let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(getRandomNumber() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array.join('');
}

/**
 * Escapes the provided string with a backslash so it can be used in a regex.
 *
 * @param {string} string - string to escape
 * @returns {string} - the escaped string
 */
function escapeRegexString(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
}

/**
 * Generates a random password
 *
 * @returns {string}
 */
function generateRandomPassword() {
  let characters = '';

  // TODO check if this if statement superfluous because useDigits and
  //  useSymbols will be disabled when choosing easyToSay
  if(options.easyToSay) {
    characters += options.useLowerCase ? LOWERCASE_WEIGHTED : '';
    characters += options.useUpperCase ? UPPERCASE_WEIGHTED : '';
  } else {
    characters += options.useLowerCase ? LOWERCASE_LETTERS : '';
    characters += options.useUpperCase ? UPPERCASE_LETTERS : '';
    characters += options.useDigits ? DIGITS : '';
    characters += options.useSymbols ? SYMBOLS : '';
  }

  // TODO add form field for avoidCharacters (text input)
  // FIXME check avoid characters format, since it's user input,
  //   make sure it's sanitised
  if(options.avoidCharacters) {
    const avoidCharsArray = [...options.avoidCharacters];
    const regexString = escapeRegexString(avoidCharsArray.join(''));
    characters = characters.replace(new RegExp(`[${regexString}]`, 'g'), '');
  }

  // early return if no characters are present
  if(characters.length === 0) {
    return '';
  }

  // Exclude ambiguous characters if enabled
  // Ambiguous characters
  // 0 (zero) and O (capital letter o)
  // 1 (one), I (capital letter i), and l (lowercase letter L)
  // 2 (two) and Z (capital letter z)
  // 5 (five) and S (capital letter s)
  // 6 (six) and G (capital letter g)
  // 8 (eight) and B (capital letter b)
  if(options.easyToRead) {
    characters = characters.replace(
      new RegExp(`[${AMBIGUOUS_CHARACTERS}]`, 'g'),
      ''
    );
  }

  let password = '';
  let length = options.passwordLength;
  // when choosing 'easy to say' `characters` contain duplicates
  const uniqueCharacterCount = [...new Set(characters)].length;

  if(options.noDuplicates && length > uniqueCharacterCount){
    length = uniqueCharacterCount;
    ELEMENTS.passwordLengthInput.domElement.value = length;
    ELEMENTS.passwordLengthRange.domElement.value = length;
    setOption('passwordLength', length);
    // TODO inform user in UI about length restriction
  }

  // shuffle characters before randomly picking from them
  characters = fisherYatesShuffle(characters);

  // Generate random password
  for(let i = 0; i < length; i++) {
    const index = Math.floor(getRandomNumber() * characters.length);
    const character = characters[index];
    password += character;

    if(options.noDuplicates){
      const regexString = escapeRegexString(character);
      const regex = new RegExp(`[${regexString}]`, 'g');
      characters = characters.replace(regex, '');
    }
  }

  return password;
}

/**
 * Displays the password strength
 *
 * @todo get HSL colour calculation
 * @param password
 */
function showPasswordStrength(password) {
  const strengthInfo = calculatePasswordStrength(password);

  // Update the strength meter width and colour based on the score
  // const strengthMeter = document.getElementById("strengthMeter");
  const strengthMeter = ELEMENTS.strengthMeter.domElement;
  strengthMeter.style.width = strengthInfo.score + "%";
  strengthMeter.style.backgroundColor = getColourForScore(strengthInfo.score);

  // Display the score, entropy, and maxEntropy to the user
  const scoreLabel = ELEMENTS.scoreLabel.domElement;
  scoreLabel.textContent = "Strength: " + strengthInfo.score;

  const entropyLabel = ELEMENTS.entropyLabel.domElement;
  entropyLabel.textContent = "Entropy: " + strengthInfo.entropy.toFixed(2) + " bits";

  const maxEntropyLabel = ELEMENTS.maxEntropyLabel.domElement;
  maxEntropyLabel.textContent = "Max Entropy: " + strengthInfo.maxEntropy.toFixed(2) + " bits";

  // Calculate the final score based on all three values
  // optional: define weighting scheme
  const maxLengthWeight = 0.5;
  const entropyWeight = 0.3;
  const scoreWeight = 0.2;

  const finalScore = (maxLengthWeight * (strengthInfo.maxEntropy / strengthInfo.entropy)) +
    (entropyWeight * (strengthInfo.entropy / strengthInfo.maxEntropy)) +
    (scoreWeight * (strengthInfo.score / 100));

  // console.log("Final Score:", finalScore);
}

/**
 * Calculates the password strength
 * // Example usage:
 * const password = "My$tr0ngP@ssw0rd";
 * const strengthScore = calculatePasswordStrength(password);
 * console.log("Password Strength Score:", strengthScore);
 *
 * @param password
 * @returns {{score: number, maxEntropyForLength: number, entropy: number, maxEntropy: number}}
 */
function calculatePasswordStrength(password) {
  // Define scoring criteria
  const upperCaseRegex = new RegExp(`[${UPPERCASE_LETTERS}]`, 'g');
  const lowerCaseRegex = new RegExp(`[${LOWERCASE_LETTERS}]`, 'g');
  const digitRegex = new RegExp(`[${DIGITS}]`, 'g');
  const symbolsRegex = new RegExp(`[${SYMBOLS}]`, 'g');

  const lengthScore = password.length * 4;
  const uppercaseScore = upperCaseRegex.test(password) ? (password.length - password.replace(upperCaseRegex, '').length) * 2 : 0;
  const lowercaseScore = /[a-z]/.test(password) ? (password.length - password.replace(lowerCaseRegex, '').length) * 2 : 0;
  const digitScore = /\d/.test(password) ? (password.length - password.replace(digitRegex, '').length) * 4 : 0;
  const symbolsScore = /[^A-Za-z0-9]/.test(password) ? (password.length - password.replace(symbolsRegex, '').length) * 6 : 0;

  // Calculate the total score
  const totalScore = lengthScore + uppercaseScore + lowercaseScore + digitScore + symbolsScore;

  // Calculate the normalized score as a percentage (max possible score is 100)
  const maxPossibleScore = 100;
  const normalizedScore = Math.min(100, Math.round((totalScore / maxPossibleScore) * 100));

  // Calculate password entropy using the actual number of different characters used
  const uniqueCharacters = new Set(password.split('')).size;
  const entropy = Math.log2(Math.pow(uniqueCharacters, password.length));

  // Calculate maximum possible entropy for the given password length
  // Considering a total of 94 printable ASCII characters
  // including letters - 2x 26, digits - 10, and special characters - 32
  // we're not using space, so 32 printable characters
  // const charsetSize = 94;

  // calculate the character size based on options
  const maxCharsetSize = UPPERCASE_LETTERS.length +
    LOWERCASE_LETTERS.length + DIGITS.length + SYMBOLS.length;
  let charsetSize = 0;

  if(options.useSymbols) {
    charsetSize += SYMBOLS.length;
  }

  if(options.useDigits) {
    charsetSize += DIGITS.length;
  }

  if(options.useUpperCase) {
    charsetSize += UPPERCASE_LETTERS.length;
  }

  if(options.useLowerCase) {
    charsetSize += LOWERCASE_LETTERS.length;
  }

  if(options.easyToRead) {
    charsetSize -= AMBIGUOUS_CHARACTERS.length;
  }

  if(options.avoidCharacters) {
    charsetSize -= options.avoidCharacters.length;
  }

  const maxEntropy = Math.log2(Math.pow(charsetSize, password.length));
  const maxEntropyForLength = Math.log2(Math.pow(maxCharsetSize, password.length));

  // console.log('charsetSize', charsetSize, {
  //   score: normalizedScore,
  //   entropy,
  //   maxEntropy,
  //   maxEntropyForLength
  // });

  return {score: normalizedScore, entropy, maxEntropy, maxEntropyForLength};
}

/**
 * returns an HSL colour for the provided score, the colour will be red or green
 *
 * @param score
 * @returns {string}
 */
function getColourForScore(score) {
  const minScore = 6;
  const minScoreForGreen = 90;
  const minHue = 0;
  const maxHue = 120;

  // Calculate the hue value for the color scale using linear interpolation
  const hue = minHue + ((score - minScore) * (maxHue - minHue)) / (minScoreForGreen - minScore);

  // console.log('>>> HSL -- hue: %s, score: %s', hue, score);

  return "hsl(" + hue + ", 100%, 50%)"; // Use HSL color to apply the color scale
}

// !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~
// Example usage:
// const password = "My$tr0ngP@ssw0rd";
// const strengthInfo = calculatePasswordStrength(password);
// console.log("Password Strength Score:", strengthInfo.score);
// console.log("Password Entropy:", strengthInfo.entropy);
// console.log("Maximum Possible Entropy:", strengthInfo.maxEntropy);
// Attach the event listener to the input field to update the password strength on each keystroke
// document.getElementById("passwordInput").addEventListener("keyup", showPasswordStrength);

// -----------------------------------------------------------------------------

// gentlemen, start your engines!
document.addEventListener('DOMContentLoaded', () => {
  init();
}, false);

// temp testing
const shareIcon = document.getElementById('share-this');
shareIcon.addEventListener('click', async () => {
  try {
    await navigator.share({
      title: 'Share this site',
      text: 'Check out this website',
      url: location.href
    });
  } catch(error) {
    console.error('Sharing failed:', error);
  }
});

